<template>
	<div class="introduce">
		<introduce></introduce>
	</div>
</template>

<script>
	import introduce from "../components/Home/introduce.vue"
	export default {
		name:'id',
		components: {
			introduce
		}
	}
</script>
<style>

</style>