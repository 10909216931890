<template>
	<div id="ntrance">
		<meta name="viewport"
			content="width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0,user-scalable=no" />
		<div class="banner"><img :src="imgUrl + info.desc_img" /></div>
		<div class="showIndex">

			<div class="content">
				<div style="height: 0.625rem;"></div>
				<div class="conName">{{ info.equity_name }}</div>
				<div class="conDet">
					<div class="conDetCon">
						<div><img src="../../image/time.png" /></div>
						<div style="font-size: 0.75rem;margin-left: 0.125rem;margin-top: 0.0625rem;color: #666666;">
							营业时间:</div>
						<div style="font-size: 0.75rem;font-weight: bold;margin-top: 0.0625rem;">
							{{ info.start_time }}-{{ info.end_time }}</div>
					</div>
					<div class="conDetCon">
						<div><img style="height: 0.75rem;" src="../../image/address.png" /></div>
						<div style="font-size: 0.75rem;margin-left: 0.125rem;margin-top: 0.0625rem;color: #666666;">
							{{ info.address }}</div>

					</div>
					<div class="conDetCon">
						<div><img src="../../image/phone.png" /></div>
						<div style="font-size:0.75rem;margin-left: 0.125rem;margin-top: 0.0625rem;color: #666666;">联系方式:
						</div>
						<div style="font-size:0.75rem;font-weight: bold;margin-top: 0.0625rem;">{{ info.user_tel }}</div>
					</div>
				</div>
				<div style="height: 0.625rem;"></div>
			</div>
		</div>
		<div class="showIndex">

			<div class="content">
				<div style="height: 0.625rem;"></div>
				<div class="conName">门店介绍</div>
				<div class="conDet1">
					{{ info.equity_desc }}
				</div>
				<div style="height: 0.625rem;"></div>
			</div>
		</div>

	</div>
</template>
<script>
import {
	Dialog
} from 'vant';
export default {

	name: "Entrance",

	data() {
		return {
			orderDetail: '',
			imgUrl: '',
			info: '',
		};
	},
	components: {},
	methods: {},
	created() {
		var imgUrl = this.$request.imgUrl()
		this.imgUrl = imgUrl
		this.id = this.$route.query.id
		this.$request.getInfo({
			id: this.$route.query.id,
		}).then(res => {
			this.info = res.data

		})
	},
	beforeCreate() { },
	mounted() { },
	destroyed() { },
};
</script>

<style scoped>
#ntrance {
	width: 100vw;
	height: 100vh;
	background: #f3f3f3;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	overflow: auto;
}

.showIndex {
	width: 94%;
	margin-left: 3%;
	margin-top: 0.625rem;

}

.banner {
	width: 100%;
	height: 30vh;
}

.banner img {
	width: 100%;
	height: 100%;
}

.content {


	align-items: center;
	width: 100%;
	/* height: 5rem; */
	background-color: white;
	border-radius: 10px;
}

.conLeft {
	/* display: flex; */
	/* width: 30%; */
	width: 4rem;
	height: 4rem;
	margin-left: 0.5rem;
	/* border: 1px solid black; */
}

.conLeft img {
	width: 100%;
	height: 100%;
}

.conRig {
	/* width: 100%; */
	display: flex;
	justify-content: space-around;
}

.conName {
	width: 90%;
	margin-left: 5%;
	font-size: 0.9375rem;
	font-weight: bold;
}

.conDet {
	width: 90%;
	margin-left: 5%;
	margin-top: 0.75rem;
	line-height: 1.5625rem;
}

.conDet1 {
	width: 90%;
	margin-left: 5%;
	margin-top: 0.75rem;
	font-size: 0.75rem;
	color: #666666;
}

.conDetCon {
	display: flex;
}

.conDetCon img {
	width: 0.625rem;
	height: 0.625rem;
}
</style>
